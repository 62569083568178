.wrapper {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
  background: #272a34;
}

.contact-wrap h3 {
  color: #fff;
}

.info-wrap {
  background: #1c1e25;
}

.info-wrap h3 {
  color: white;
  font-size: 18px;
  position: relative;
}

.info-wrap .dbox {
  width: 100%;
  margin-bottom: 25px;
}

.info-wrap .dbox:last-child {
  margin-bottom: 0;
}

.info-wrap .dbox p {
  margin-bottom: 0;
}

.info-wrap .dbox p span {
  font-weight: 400;
  color: #fff;
}

.info-wrap .dbox p a {
  color: rgba(255, 255, 255, 0.3);
}

.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.02);
}

.info-wrap .dbox .icon span {
  font-size: 20px;
  color: #fff;
}

.info-wrap .dbox .text {
  width: calc(100% - 50px);
}

.form-control {
  margin-top: 3px;
  padding: 10px;
  background: transparent;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 14px;
  border-radius: 0px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control:-ms-input-placeholder {
  /* IE 0+ */
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5) !important;
}

.form-control:focus,
.form-control:active {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

textarea.form-control {
  height: inherit !important;
}
